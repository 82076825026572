import DisplayBadge from 'component/custom-page/common/badge/DisplayBadge';
import DisplayDate from 'component/custom-page/common/date/DisplayDate';
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import { CustomBoardContent } from 'types/feature/customBoard.type';
import useDomainStore from 'stores/domain';
import styles from './CardTitle.module.scss';

type Props = {
  board: CustomBoardContent;
};

const CardTitle = ({ board }: Props) => {
  const languageStore = useLanguageStore();
  const colorSetStore = useColorSetStore();
  const domainStore = useDomainStore();

  return domainStore.domainType === 'WEB' ? (
    <div className={styles.web_title_wrapper}>
      <div className={styles.title_and_badge}>
        {/* 배지 */}
        <DisplayBadge displayType={board.displayStatus} />

        {/* 타이틀 */}
        <div
          className={styles.title}
          style={{
            color: colorSetStore.sub,
          }}
        >
          {board.title[languageStore.currentLang] || '-'}
        </div>
      </div>

      {/* 노출 기간 */}
      <DisplayDate board={board} />
    </div>
  ) : (
    <div className={styles.title_wrapper}>
      <div className={styles.title_and_badge}>
        {/* 배지 */}
        <DisplayBadge displayType={board.displayStatus} />

        {/* 타이틀 */}
        <div
          className={styles.title}
          style={{
            color: colorSetStore.sub,
          }}
        >
          {board.title[languageStore.currentLang] || '-'}
        </div>
      </div>

      {/* 노출 기간 */}
      <DisplayDate board={board} />
    </div>
  );
};
export default CardTitle;
