/* eslint-disable no-nested-ternary */
import useColorSetStore from 'stores/colorSet';
import useLanguageStore from 'stores/languages';
import useMachineStore from 'stores/machine';
import useDomainStore from 'stores/domain';
import Clock from '../clock/Clock';
import BusinessHour from '../info/business-hour/BusinessHour';
import ClosedDays from '../info/closed-days/ClosedDays';
import MultiLanguageBtn from '../language/MultiLangBtn';
import styles from './VertiMallInfo.module.scss';
import MultiLanguageBtnByWeb from '../language/MultiLangBtnByWeb';

// 세로형 mall info
const VertiMallInfo = () => {
  const langStore = useLanguageStore();
  const machineStore = useMachineStore();
  const colorSetStore = useColorSetStore();
  const domainStore = useDomainStore();

  const isShowClosedDays = machineStore.machine?.point.closedDaysUsed;
  const isShowClock = !machineStore.machine?.point.hoursUsed && !machineStore.machine?.point.closedDaysUsed;

  return (
    <div style={{ backgroundColor: colorSetStore.footer.bg }} className={styles.container}>
      <div className={styles.mall_info}>
        {/* 시계 */}
        {isShowClock && <Clock />}
        {/* 운영시간 */}
        <BusinessHour />
        {/* 휴점일 */}
        {isShowClosedDays && <ClosedDays />}
      </div>
      {langStore.languages.length >= 2 &&
        (domainStore.domainType === 'WEB' ? <MultiLanguageBtnByWeb /> : <MultiLanguageBtn />)}
    </div>
  );
};

export default VertiMallInfo;
